<template>
  <div class="lg:flex justify-center lg:pt-4">
    <div class="b-card lg:mt-24 lg:px-20 lg:pt-8">
      <div class="px-6 py-2 flex flex-col text-text-primary">
        <header class="py-2.5 text-lg flex text-text-alternate-1 pr-6">
          <button class="focus:outline-none" @click="$router.go(-1)">
            <LeftArrowIcon class="h-6 fill-current" />
          </button>
          <h2 class="mx-auto font-semibold">Fundraiser History</h2>
        </header>
        <BPageLoader v-if="isLoading" />
        <main v-else>
          <ul class="block rounded bg-bg-alternate-3 p-4 sm:py-6 my-4">
            <li>
              <h5 class="font-medium text-xs text-text-alternate-4">
                Total Donations
              </h5>
              <p class="font-bold text-lg pt-2">
                ₦ {{ data.totalDonations.toLocaleString() }}
              </p>
            </li>
            <li class="mt-4">
              <h5 class="font-medium text-xs text-text-alternate-4">
                Total Applicable Commissions
              </h5>
              <p class="font-bold text-sm pt-2">
                ₦ {{ data.totalCommission.toLocaleString() }}
              </p>
            </li>
            <li class="mt-4">
              <h5 class="font-medium text-xs text-text-alternate-4">
                Total Promote & Earn Commissions
              </h5>
              <p class="font-bold text-sm pt-2">
                ₦ {{ data.totalPromoteAndEarn.toLocaleString() }}
              </p>
            </li>
            <li class="mt-4">
              <h5 class="font-medium text-xs text-text-alternate-4">
                Net Donations
              </h5>
              <p class="font-bold text-sm pt-2">
                ₦ {{ data.netDonations.toLocaleString() }}
              </p>
            </li>
            <li class="mt-4">
              <h5 class="font-medium text-xs text-text-alternate-4">
                Current Balance
              </h5>
              <p class="font-bold text-sm pt-2">
                ₦ {{ data.currentBalance.toLocaleString() }}
              </p>
            </li>
          </ul>
          <ul class="text-xs font-semibold">
            <li class="flex justify-between my-4">
              <span class="text-text-alternate-3">Fundraiser ID</span>
              <span class="text-text-alternate-1">{{ data.id }}</span>
            </li>
            <li class="flex justify-between my-4">
              <span class="text-text-alternate-3">Fundraiser Type</span>
              <span class="text-text-alternate-1 capitalize">
                {{ data.fundraiserType.toLowerCase() }}
              </span>
            </li>
            <li class="flex justify-between my-4">
              <span class="text-text-alternate-3">Created</span>
              <span class="text-text-alternate-1">
                {{ dayjs(data.createdAt).format("DD MMM YYYY") }}
              </span>
            </li>
            <li class="flex justify-between my-4" v-if="isOneTime">
              <span class="text-text-alternate-3">Ends</span>
              <span class="text-text-alternate-1">
                {{
                  dayjs(data.createdAt)
                    .add(data.duration, "day")
                    .format("DD MMM YYYY")
                }}
              </span>
            </li>
            <li class="flex justify-between my-4" v-if="isOneTime">
              <span class="text-text-alternate-3">Duration</span>
              <span class="text-text-alternate-1">
                {{ data.duration }} days</span
              >
            </li>
            <li class="flex justify-between my-4" v-else>
              <span class="text-text-alternate-3">Cycle</span>
              <span class="text-text-alternate-1 capitalize">
                {{ data.fundraiserCycle.toLowerCase() }}
              </span>
            </li>
            <li class="flex justify-between my-4">
              <span class="text-text-alternate-3">Target Amount</span>
              <span class="text-text-alternate-1">
                ₦{{ data.targetAmount.toLocaleString() }}
              </span>
            </li>
            <li class="flex justify-between my-4">
              <span class="text-text-alternate-3">Commission Plan</span>
              <span class="text-text-alternate-1">{{ data.commission }}</span>
            </li>
            <li class="flex justify-between my-4">
              <span class="text-text-alternate-3">Has Promote & Earn</span>
              <span class="text-text-alternate-1">{{ data.hasPromoteAndEarn }}</span>
            </li>
            <li class="flex justify-between my-4">
              <span class="text-text-alternate-3">Promote & Earn Commission Percentage</span>
              <span class="text-text-alternate-1">{{ data.promoteAndEarnCommPercent }}%</span>
            </li>
            <li class="flex justify-between my-4">
              <span class="text-text-alternate-3">Number of Promote & Earn</span>
              <span class="text-text-alternate-1">{{ data.noOfPromoteAndEarn }}</span>
            </li>
            <li class="flex justify-between my-4">
              <span class="text-text-alternate-3">Promotion</span>
              <span class="text-text-alternate-1">
                {{ data.isPromoted ? "Promoted" : "Not Promoted" }}
              </span>
            </li>
            <li class="flex justify-between my-4">
              <span class="text-text-alternate-3">Total Promotions</span>
              <span class="text-text-alternate-1">
                ₦{{ data.totalPromotions.toLocaleString() }}
              </span>
            </li>
            <li class="flex justify-between my-4">
              <span class="text-text-alternate-3">Status</span>
              <span class="text-text-alternate-1 capitalize">
                {{ data.status.toLowerCase() }}
              </span>
            </li>
          </ul>
          <hr class="my-4 text-text-alternate-8" />
        </main>
      </div>
    </div>
  </div>
</template>

<script>
import { toRefs, computed } from "vue";
import { useApi } from "@/cmp-functions/useApi";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import { getFundraiserHistory } from "@/services/api";
import dayjs from "dayjs";

export default {
  name: "FundraiserHistory",

  setup() {
    const route = useRoute();
    const store = useStore();
    const authUser = store.state.auth.authData;
    const { id: fundraiserId } = route.params;
    const [response, fetchFundraiserHistory] = useApi(getFundraiserHistory);

    const isOneTime = computed(
      () => response.data.fundraiserType !== "RECURRING"
    );

    fetchFundraiserHistory({ fundraiserId, userId: authUser.id });

    return { ...toRefs(response), dayjs, isOneTime };
  },
};
</script>
